import { generateHrefLangUrl } from '@/lib/utils';
import Head from 'next/head';
import { useRouter } from 'next/router';

interface HeadCIC {
  seo: {
    title: string;
    description: string;
    canonicalUrl: string;
    nextPage: string;
    nofollow: boolean;
    noindex: boolean;
    image: {
      url: string;
      width: number;
      height: number;
      title: string;
      description: string;
    };
    ldType: string;
    ldAuthor: string;
    ldAuthorUrl: string;
    ldPublished: string;
    ldUpdated: string;
  };
}

const HeadC = ({ seo }: HeadCIC) => {
  // Defaults if something is missing
  const router = useRouter();
  const head = {
    title: seo?.title || 'Consulteer | Your one stop digital agency',
    description:
      seo?.description ||
      'Consulteer is about smart product problem solving for tomorrow relevant businesses.',
    canonicalUrl: seo?.canonicalUrl || process.env.SITE_URL,
    nextPage: seo?.nextPage || process.env.SITE_URL,
    nofollow: seo?.nofollow || false,
    noindex: seo?.noindex || false,
    image: {
      url:
        seo?.image?.url ||
        'https://images.ctfassets.net/mr98g2zcqin4/3d0B6KIpFZWe4K4LB0pfDc/5f9cce3952a6527f4e5c7dfab2c747df/consulteer-home.png',
      width: seo?.image?.width || 1200,
      height: seo?.image?.height || 630,
      title: seo?.image?.title || 'Consulteer | Your one stop digital agency',
      description:
        seo?.image?.description ||
        'Making extraordinary market-ready services, products & platforms.'
    },
    ldType: seo?.ldType || 'WebPage',
    ldAuthor: seo?.ldAuthor || 'Consulteer team',
    ldAuthorUrl: seo?.ldAuthorUrl || process.env.SITE_URL,
    ldPublished: seo?.ldPublished || null,
    ldUpdated: seo?.ldUpdated || null
  };

  // JSON + LD Schema
  const schema = {
    '@context': 'https://schema.org',
    '@type': head.ldType,
    publisher: {
      '@type': 'Organization',
      name: 'Consulteer',
      url: process.env.SITE_URL,
      logo: {
        '@type': 'ImageObject',
        url: `${process.env.SITE_URL}content/images/2022/04/logo.svg`,
        width: 214,
        height: 60
      }
    },
    author: {
      '@type': 'Person',
      name: head.ldAuthor,
      url: head.ldAuthorUrl,
      sameAs: []
    },
    headline: head.title,
    url: head.canonicalUrl,
    datePublished: head.ldPublished,
    dateModified: head.ldUpdated,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': process.env.SITE_URL
    },
    image: {
      '@type': 'ImageObject',
      url: head.image.url,
      width: head.image.width,
      height: head.image.height
    },
    description: head.description
  };

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <title>{head.title}</title>
      <meta name="description" content={head.description} />

      <link rel="icon" href="/consulteer-logo-mark-512x512.png" />
      <meta name="generator" content="Generated by Consulteer Team (c) 2023" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <link rel="canonical" href={head.canonicalUrl} />
      <link rel="next" href={head.nextPage} />

      {/* Meta Robots */}
      <meta
        name="robots"
        content={`${head.noindex ? 'noindex' : 'index'}${
          head.nofollow ? ', nofollow' : ', follow'
        }`}
      />

      {/* Meta Social networks */}
      <meta property="og:site_name" content="Consulteer" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={head.title} />
      <meta property="og:description" content={head.description} />
      <meta property="og:url" content={head.canonicalUrl} />
      <meta property="og:image" content={head.image.url} />
      <meta property="og:image:width" content={`${head.image.width}`} />
      <meta property="og:image:height" content={`${head.image.height}`} />

      <meta
        property="article:publisher"
        content="https://www.facebook.com/consulteer"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Consulteer" />
      <meta name="twitter:description" content={head.description} />
      <meta name="twitter:url" content={head.canonicalUrl} />
      <meta name="twitter:image" content={head.image.url} />
      <meta name="twitter:site" content="@consulteerag" />

      {/* HrefLang tag implementation */}
      {router?.locales?.map((locale) => {
        return (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale === 'en-US' ? 'en' : locale}
            href={generateHrefLangUrl(locale, router.asPath)}
          />
        );
      })}

      {/* Meta Schema JSON + LD */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Head>
  );
};

export default HeadC;
